import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import search from 'assets/svg/search.svg';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
} from '@material-ui/core';
import axios from 'axios';
import { TIMEOUT } from 'utils/consts';
import { baseURL } from 'utils/apiEndpoints';
import * as _ from 'lodash';
import * as moment from 'moment';
import MultiSelect from 'UI/multiSelect';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from 'UI/autocomplete';
import ItineraryRequirement from 'components/dashboard/itineraryRequirement';
import CreateIcon from '@material-ui/icons/CreateNewFolder';
import { InView } from 'react-intersection-observer';
import Header from 'UI/header';
import { isEqual } from 'utils/common';
import Loader from 'UI/loader';

class ItineraryMaker extends Component {
  constructor(props) {
    super(props);
    // const { token, expert } = this.props;
    // this.headers = headerGenerator(token);

    const nights = [
      {
        value: 1,
        name: '1 night',
      },
      {
        value: 2,
        name: '2 nights',
      },
      {
        value: 3,
        name: '3 nights',
      },
      {
        value: 4,
        name: '4 nights',
      },
      {
        value: 5,
        name: '5 nights',
      },
      {
        value: 6,
        name: '6 nights',
      },
      {
        value: 7,
        name: '7 nights',
      },
      {
        value: 8,
        name: '8 nights',
      },
      {
        value: 9,
        name: '9 nights',
      },
      {
        value: 10,
        name: '10 nights',
      },
      {
        value: 11,
        name: '11 nights',
      },
      {
        value: 12,
        name: '12 nights',
      },
      {
        value: 13,
        name: '13 nights',
      },
      {
        value: 14,
        name: '14 nights',
      },
      {
        value: 15,
        name: '15 nights',
      },
    ];
    this.state = {
      nights,
      nightOptions: nights,
      start: 0,
      limit: 10,
      isSubmitting: false,
    };

    this.debouncedGetTemplates = _.debounce(() => this.getTemplates(), 500);
    this.debouncedGetRequests = _.debounce((value) => this.getRequests(value), 500);
  }

  componentDidMount = () => {
    this.getTemplates({});
  };

  componentDidUpdate(prevProps) {
    const { saveItineraryDraftResp } = this.props;
    const { selectedRequest } = this.state;
    if (!isEqual(saveItineraryDraftResp, prevProps.saveItineraryDraftResp)
      && saveItineraryDraftResp.message) {
      const { request: { draftedItineraryId } } = saveItineraryDraftResp;
      window.open(`/itineraryMaker/request/${selectedRequest.usId}/${draftedItineraryId}`, '_blank');
      this.setState({
        editItinerary: false,
        isSubmitting: false,
      });
    }
  }

  getTemplates = async (payload) => {
    const { nextPage } = payload;
    const { showSnackbar } = this.props;
    const {
      searchQuery = '',
      limit,
      selectedNights = [],
      places,
      placeOptions,
      selectedPlaces = [],
      selectedStarRating,
      labels,
      labelOptions = [],
      selectedLabels = [],
      templates = [],
      totalCount,
    } = this.state;

    if (templates.length >= totalCount) {
      return;
    }

    let { start } = this.state;
    if (nextPage && (templates.length < totalCount)) {
      start += limit;
    }
    await this.setState({
      start,
      isFetchingFirstPage: start === 0,
      isFetchingNextPage: start > 0,
    });
    const url = `${baseURL}itineraries/getTemplates`;
    axios({
      method: 'post',
      url,
      headers: this.headers,
      timeout: TIMEOUT,
      data: {
        searchQuery,
        placeIds: selectedPlaces.map((p) => p._id),
        start,
        limit,
        nights: selectedNights.map((n) => n.value),
        labels: selectedLabels.map((l) => l.text),
        starRating: selectedStarRating,
        getPlaces: _.isEmpty(places),
        getLabels: _.isEmpty(labels),
      },
    }).then((response) => {
      const setStateData = {
        isFetchingFirstPage: false,
        isFetchingNextPage: false,
        templates: _.concat((start === 0 ? [] : templates), response?.data?.itineraryTemplates || []),
        totalCount: response?.data?.totalCount || 10,
      };
      if (_.isEmpty(places) && !_.isEmpty(response?.data?.places)) {
        setStateData.places = response?.data?.places || [];
      }
      if (_.isEmpty(placeOptions) && !_.isEmpty(response?.data?.places)) {
        setStateData.placeOptions = response?.data?.places || [];
      }
      if (_.isEmpty(labels) && !_.isEmpty(response?.data?.labels)) {
        setStateData.labels = response?.data?.labels || [];
      }
      if (_.isEmpty(labelOptions) && !_.isEmpty(response?.data?.labels)) {
        setStateData.labelOptions = response?.data?.places || [];
      }
      this.setState(setStateData);
    }).catch(() => {
      showSnackbar('Something went wrong, please retry', 'error');
    });
  }

  getRequests = async (value) => {
    if (_.isEmpty(value)) {
      return;
    }
    const { showSnackbar } = this.props;
    const url = `${baseURL}requests/search`;
    axios({
      method: 'post',
      url,
      headers: this.headers,
      timeout: TIMEOUT,
      data: {
        searchQuery: value,
        getPlaces: true,
      },
    }).then((response) => {
      const setStateData = {
        requestOptions: response?.data?.requests.map((r) => {
          const rCopy = r;
          rCopy.displayValue = `(${r.usId}) ${r.primaryCustomerData.firstName} ${r.primaryCustomerData.lastName}`
            + ` ${r.primaryCustomerData.phoneDialCode}${r.primaryCustomerData.phone}`;
          return rCopy;
        }),
        requestPlaces: response?.data?.places || [],
      };
      this.setState(setStateData);
    }).catch(() => {
      showSnackbar('Something went wrong, please retry', 'error');
    });
  }

  updateRequest = (payload) => {
    const { saveItineraryDraft } = this.props;
    const { selectedRequest } = this.state;
    this.setState({
      isSubmitting: true,
    });
    saveItineraryDraft({
      details: {
        requirement: {
          ...payload,
          templateItineraryId: undefined,
        },
        routes: [],
        itineraryParts: [],
        inclusions: [],
        exclusions: [],
        tags: payload.tags || [],
        templateItineraryId: payload.templateItineraryId,
      },
      requestId: selectedRequest._id,
    }, this.headers);
  };

  render() {
    const TravelCard = ({ template }) => {
      const {
        image = 'https://i.redd.it/mtc4lar01q931.jpg',
        title,
        nights,
        refPrice,
        oldPrice,
        discount,
        bookBy,
        travelBy,
        starRating,
        labels = [],
      } = template;

      return (
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={image}
            title={title}
          />
          <CardContent className={classes.content}>
            <div>
              <div style={{ fontSize: 20, fontWeight: 'bold', minHeight: '60px' }}>{title}</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={classes.italicText}>Nights: {nights}</div>
                {
                  bookBy
                    ? <div className={classes.italicText}>Book by: {moment(bookBy).format('D, MMM YYYY')}</div>
                    : null
                }
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {
                  starRating
                    ? <div><span className={classes.italicText}>Hotel Stars:</span> {'⭐⭐⭐⭐⭐⭐⭐'.substring(0, starRating)}</div>
                    : null
                }
                {
                  travelBy
                    ? <div className={classes.italicText}>Travel by: {moment(travelBy).format('D, MMM YYYY')}</div>
                    : null
                }
              </div>
              <Box className={classes.chipContainer}>
                {labels.map((label) => (
                  <div
                    style={{
                      border: `1px solid ${label.color}`,
                      backgroundColor: label.color,
                      color: 'white',
                      height: '24px',
                      borderRadius: '16px',
                      padding: '5px 10px',
                      fontSize: '12px',
                      fontFamily: 'Lato',
                      lineHeight: '14px',
                    }}
                  >
                    {label.text}
                  </div>
                ))}
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <div>
                  <span className={classes.price}>{refPrice.value}</span>
                  <span className={classes.italicText}>{refPrice.info}</span>
                </div>
                {
                  oldPrice
                    ? (
                      <Typography className={classes.oldPrice}>
                        {oldPrice}
                        <span style={{ color: 'orange' }}>{discount || ''}</span>
                      </Typography>
                    ) : null
                }
              </div>
              <div
                style={{
                  width: '40%',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.previewButton}
                  onClick={() => {
                    this.setState({
                      showItinerary: true,
                      selectedItinerary: template,
                    });
                  }}
                >
                  Preview
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      );
    };
    const { classes, showSnackbar, meta } = this.props;
    const {
      templates = [],
      searchQuery,
      nights = [],
      nightOptions = [],
      selectedNights = [],
      nightsValue = '',
      places = [],
      placeOptions = [],
      selectedPlaces = [],
      placeInputValue = '',
      labels = [],
      labelOptions = [],
      selectedLabels = [],
      labelInputValue = '',
      selectedStarRating,
      selectedItinerary,
      showItinerary,
      editItinerary,
      requestOptions,
      selectedRequest,
      requestInputValue = '',
      requestPlaces,
      isSubmitting,
      isFetchingFirstPage,
      isFetchingNextPage,
    } = this.state;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className={classes.toolbar}>
          <div className={classes.search}>
            <img src={search} alt="s" className={classes.searchIcon} />
            <InputBase
              classes={{ input: classes.input }}
              className={classes.searchInput}
              placeholder="Search by itinerary name, countries, cities, stays, activities"
              inputProps={{ 'aria-label': 'search lead' }}
              value={searchQuery}
              onChange={async (e) => {
                this.setState({
                  searchQuery: e.target.value,
                });
                this.debouncedGetTemplates({});
              }}
              endAdornment={
                !_.isEmpty(searchQuery)
                  ? (
                    <IconButton
                      onClick={async () => {
                        await this.setState({ searchQuery: '' });
                        this.getTemplates({});
                      }}
                      className={classes.searchCloseBtn}
                    >
                      <CloseIcon className={classes.searchCloseIcon} />
                    </IconButton>
                  )
                  : null
              }
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                marginBottom: '2px',
              }}
            >
              Hotel Category
            </div>
            <div style={{ height: '16px' }}>
              {[1, 2, 3, 4, 5].map((starRating) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  key={starRating}
                  onClick={async () => {
                    await this.setState({
                      selectedStarRating: selectedStarRating === starRating
                        ? 0
                        : starRating,
                    });
                    this.getTemplates({});
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '14px',
                    lineHeight: '16px',
                  }}
                >
                  {
                    (!_.isNil(selectedStarRating) && (starRating <= selectedStarRating))
                      ? (<span role="img" aria-label={`${starRating} star`}> ⭐ </span>)
                      : (<span role="img" aria-label={`${starRating} star`}> ☆ </span>)
                  }
                </span>
              ))}
            </div>
          </div>
          <MultiSelect
            extraClass={classes.placeFilter}
            inputClass={classes.filterInput}
            placeholder="Choose destination(s)"
            selections={selectedPlaces}
            value={placeInputValue}
            accessor="name"
            renderType="place"
            data={placeOptions}
            removeHandler={async (item) => {
              await this.setState({
                selectedPlaces: selectedPlaces.filter((p) => p._id !== item._id),
              });
              this.getTemplates({});
            }}
            onChange={(value) => {
              if (_.isEmpty(value)) {
                this.setState({
                  placeOptions: places,
                  placeInputValue: value,
                });
              } else {
                this.setState({
                  placeOptions: places.filter((p) => p.name.toLowerCase()
                    .includes(value.toLowerCase())),
                  placeInputValue: value,
                });
              }
            }}
            onSelected={async (item) => {
              selectedPlaces.push(item);
              await this.setState({
                selectedPlaces,
              });
              this.getTemplates({});
            }}
          />
          <MultiSelect
            extraClass={classes.nightFilter}
            inputClass={classes.filterInput}
            placeholder="Trip Duration"
            selections={selectedNights}
            value={nightsValue}
            renderType="place"
            accessor="name"
            data={nightOptions}
            removeHandler={async (item) => {
              await this.setState({
                selectedNights: selectedNights.filter((n) => n.value !== item.value),
              });
              this.getTemplates({});
            }}
            onChange={(value) => {
              if (_.isEmpty(value)) {
                this.setState({
                  nightOptions: nights,
                  nightsValue: value,
                });
              } else {
                this.setState({
                  nightOptions: nights.filter((n) => n.value.toString()
                    .includes(value.toString())),
                  nightsValue: value,
                });
              }
            }}
            onSelected={async (item) => {
              selectedNights.push(item);
              await this.setState({
                selectedNights,
              });
              this.getTemplates({});
            }}
          />
          <MultiSelect
            extraClass={classes.labelFilter}
            inputClass={classes.filterInput}
            placeholder="Tags"
            selections={selectedLabels}
            value={labelInputValue}
            accessor="text"
            renderType="place"
            data={labelOptions}
            removeHandler={async (item) => {
              await this.setState({
                selectedLabels: selectedLabels.filter((l) => l.text !== item.text),
              });
              this.getTemplates({});
            }}
            onChange={(value) => {
              if (_.isEmpty(value)) {
                this.setState({
                  labelOptions: labels,
                  labelInputValue: value,
                });
              } else {
                this.setState({
                  labelOptions: labels.filter((l) => l.text === value),
                  labelInputValue: value,
                });
              }
            }}
            onSelected={async (item) => {
              selectedLabels.push(item);
              await this.setState({
                selectedLabels,
              });
              this.getTemplates({});
            }}
          />
        </div>
        <div
          style={{
            paddingTop: 20,
            maxWidth: 1300,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {
            isFetchingFirstPage
              ? (
                <div className="loader">
                  <Loader />
                </div>
              )
              : (
                <div>
                  <Grid container spacing={2}>
                    {
                      templates.map((template) => (
                        <Grid item xs={12} sm={6} justify="center">
                          <TravelCard template={template} />
                        </Grid>
                      ))
                    }
                  </Grid>
                  <InView onChange={(inView) => inView && !isFetchingNextPage && this.getTemplates({ nextPage: true })}>
                    {
                      ({ ref }) => (
                        <div ref={ref} style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}>
                          {isFetchingNextPage ? <Loader /> : 'That\'s all folks!'}
                        </div>
                      )
                    }
                  </InView>
                </div>
              )
          }
        </div>
        <Dialog
          open={showItinerary}
          classes={classes.dialog}
          fullWidth
          maxWidth={false}
          onClose={() => { this.setState({ showItinerary: false }); }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              minHeight: '56px',
              background: 'linear-gradient(90deg, #3AAEA9 0%, #16242A 100%)',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <Button
              className={classes.copyLinkButton}
              onClick={() => {
                navigator.clipboard.writeText(`${selectedItinerary.previewLink}?r=1`);
                showSnackbar('Link copied!', 'success');
              }}
            >
              Copy Link
            </Button>
            <Button
              className={classes.editItineraryButton}
              onClick={() => {
                this.setState({
                  showItinerary: false,
                  editItinerary: true,
                  isSubmitting: false,
                  requestInputValue: null,
                  selectedRequest: null,
                });
              }}
            >
              Use Template
            </Button>
          </div>
          <iframe
            title={selectedItinerary?.title}
            src={`${selectedItinerary?.previewLink}?showPdfCompatibleLinks=true&wf=1`}
            style={{ height: '600px', width: '100%' }}
          />
        </Dialog>
        <Dialog
          open={editItinerary}
          classes={classes.dialog}
          fullWidth
          maxWidth={false}
          onClose={() => { this.setState({ editItinerary: false }); }}
        >
          <div
            style={{
              height: '600px',
              padding: '20px 0 20px 0',
            }}
          >
            <Header
              onDismiss={() => this.setState({ editItinerary: false })}
              title={`Copy Itinerary Template : ${selectedItinerary?.title}`}
              Icon={CreateIcon}
              iconClass={classes.headerIconClass}
            />

            <div style={{ marginTop: 20, padding: '10px 40px' }}>
              <Autocomplete
                label="USID"
                value={requestInputValue}
                data={requestOptions}
                placeholder="Search by USID/name/phone/email"
                accessor="displayValue"
                extraClass={classes.usIdFilter}
                inputClass={classes.usIdInput}
                onChange={(value) => {
                  this.setState({
                    requestInputValue: value,
                  });
                  this.debouncedGetRequests(value);
                }}
                onSelected={(item) => {
                  this.setState({
                    selectedRequest: item,
                    requestInputValue: item.displayValue,
                  });
                }}
              />
              {
                selectedRequest
                  ? (
                    <ItineraryRequirement
                      requirement={selectedRequest.requirement}
                      request={selectedRequest}
                      onSubmit={this.updateRequest}
                      meta={meta}
                      isLoading={isSubmitting}
                      fetchedPlaces={_.concat(places, requestPlaces)}
                      selectedItinerary={selectedItinerary}
                    />
                  )
                  : null
              }
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

/**
 * @param {import('@material-ui/core/styles/createMuiTheme').Theme} theme
 */
const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 56px)',
    overflowY: 'auto',
    backgroundColor: theme.colors.white,
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 600,
    flexGrow: 1,
    transition: 'width 0.4s ease-in-out',
    '&:focus': {
      width: '100%',
    },
    '&:focus $tabButtonActive, &:focus $tabButton': {
      width: 0,
      minWidth: 0,
      padding: 0,
    },
  },
  searchIcon: {
    marginRight: 10,
    height: 16,
    width: 16,
  },
  searchInput: {
    width: '100%',
    '&:focus-within $searchCloseBtn': {
      display: 'block',
    },
  },
  toolbar: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    padding: '8px 40px',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    zIndex: 1,
    boxShadow: '1px 2px 10px 1px rgba(0,0,0,0.1)',
  },
  card: {
    display: 'flex',
    borderRadius: '24px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginBottom: '16px',
    width: 600,
    height: 250,
  },
  media: {
    width: '200px',
    height: 'auto',
  },
  content: {
    width: '400px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  chipContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    margin: '15px 0',
  },
  price: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Lato',
  },
  oldPrice: {
    fontSize: '20px',
    color: '#999999',
    textDecoration: 'line-through',
  },
  previewButton: {
    border: '1px solid #2C7A77',
    color: '#2C7A77',
    borderRadius: 40,
    fontSize: '12px',
    alignSelf: 'center',
    height: '28px',
  },
  placeFilter: {
    width: '220px',
    border: '1px solid #DDDDDD',
    borderRadius: '4px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  nightFilter: {
    width: '180px',
    border: '1px solid #DDDDDD',
    borderRadius: '4px',
    marginRight: '20px',
  },
  labelFilter: {
    width: '180px',
    border: '1px solid #DDDDDD',
    borderRadius: '4px',
  },
  filterInput: {
    padding: '0px 5px 0px 15px',
    '& > input': {
      fontSize: '14px',
    },
  },
  copyLinkButton: {
    color: 'white',
    backgroundColor: 'transparent',
    border: '1px solid white',
    fontWeight: 'bold',
    height: '32px',
    borderRadius: '16px',
    marginRight: 10,
  },
  editItineraryButton: {
    color: '#16242A',
    backgroundColor: 'white',
    border: '1px solid white',
    fontWeight: 'bold',
    height: '32px',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: '#d3d3d3',
    },
  },
  usIdFilter: {
    width: '600px',
  },
  usIdInput: {
    padding: '10px',
    width: '600px',
  },
  italicText: {
    fontSize: '14px',
    fontStyle: 'italic',
    fontFamily: 'Lato',
  },
  headerIconClass: {
    color: '#2C7A77',
  },
});

ItineraryMaker.propTypes = {
  history: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  showSnackbar: PropTypes.func,
  saveItineraryDraft: PropTypes.func,
  saveItineraryDraftResp: PropTypes.object,
};

export default hot(withStyles(styles)(ItineraryMaker));
